import "../styles/components/team-card.scss";

import React from "react";
import TeamPlaceholder from "../images/team-placeholder.png";

interface TeamCardProps {
  teamData: {
    title: string;
    databaseId: string;
    postTemplateTeam: {
      title: string;
      profileUrl: string;
      photo: {
        altText: string;
        sourceUrl: string;
      };
    };
  };
}

/**
 * @param props
 * @param props.teamData
 */
function TeamCard({ teamData }: TeamCardProps) {
  return (
    <div className="TeamCard">
      <a
        className="TeamCard-link"
        href={teamData?.postTemplateTeam?.profileUrl}
        rel="noreferrer"
        target="_blank"
      >
        <div className="TeamCard-imageContainer">
          {teamData?.postTemplateTeam?.photo ? (
            <img
              alt={teamData?.postTemplateTeam?.photo?.altText}
              className="TeamCard-image"
              src={teamData?.postTemplateTeam?.photo?.sourceUrl}
            />
          ) : (
            <img alt="" className="TeamCard-image" src={TeamPlaceholder} />
          )}
        </div>
        <div className="TeamCard-text">
          {teamData?.title && (
            <h2 className="TeamCard-name">{teamData?.title}</h2>
          )}
          {teamData?.postTemplateTeam?.title && (
            <div
              className="TeamCard-title"
              dangerouslySetInnerHTML={{
                __html: teamData?.postTemplateTeam?.title,
              }}
            />
          )}
        </div>
      </a>
    </div>
  );
}

export default TeamCard;
