import "../styles/components/scroll-translate.scss";

import React, { ForwardedRef, useEffect } from "react";

import classNames from "classnames";

interface ScrollTranslateProps {
  children: React.ReactNode;
  className?: string;
  preventDefault?: boolean;
  stopPropagation?: boolean;
  wheelRef?: ForwardedRef<HTMLElement>;
}

/**
 * @param props
 * @param props.children
 * @param props.className
 * @param props.wheelRef
 */
const ScrollTranslate = React.forwardRef(
  ({ children, className, wheelRef }: ScrollTranslateProps, ref) => {
    /**
     * @param e
     */
    function handleWheel(e) {
      if (ref?.current && e.deltaY !== 0) {
        ref.current.scrollLeft += e.deltaY + e.deltaX;
        e.preventDefault();
      }
    }

    useEffect(() => {
      if (wheelRef?.current)
        wheelRef?.current?.addEventListener("wheel", handleWheel, {
          passive: false,
        });
      return () => {
        if (wheelRef?.current)
          wheelRef?.current?.removeEventListener("wheel", handleWheel, {
            passive: false,
          });
      };
    }, []);

    return (
      <div className={classNames(className, "ScrollTranslate")} ref={ref}>
        {children}
      </div>
    );
  }
);

ScrollTranslate.displayName = "ScrollTranslate";

ScrollTranslate.defaultProps = {
  preventDefault: false,
  stopPropagation: false,
};

export default ScrollTranslate;
