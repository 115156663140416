import "../../styles/pages/team.scss";

import React, { useRef } from "react";

import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import ScrollTranslate from "../../components/ScrollTranslate";
import TeamCard from "../../components/TeamCard";

/**
 *
 */
function Team({ pageContext }) {
  const { seo } = pageContext;
  const { title, metaDesc } = seo;
  const wpPageData = pageContext;

  const scrollRef = useRef(null);
  const wheelRef = useRef(null);

  const items = wpPageData?.pageTemplateTeam?.teamMember || [];
  const heading = wpPageData?.pageTemplateTeam?.heading;
  const body = wpPageData?.pageTemplateTeam?.body;

  return (
    <Layout ref={wheelRef} theme="dark">
      <SEO title={title} description={metaDesc} />

      <article className="Team">
        <header className="Team-header">
          {heading && <h1 className="Team-headerHeading">{heading}</h1>}
          <p className="Team-headerText">{body}</p>
        </header>
        <div className="Team-main">
          <div className="Team-listContainer">
            <ScrollTranslate
              className="Team-list"
              ref={scrollRef}
              wheelRef={wheelRef}
            >
              {items.map((item, i) => (
                <div className="Team-item Team-item--active" key={i}>
                  <TeamCard teamData={item} />
                </div>
              ))}
            </ScrollTranslate>
          </div>
        </div>
      </article>
    </Layout>
  );
}

export default Team;
// const wpPageData = useStaticQuery(graphql`
// query {
//   wpPage(slug: { eq: "team" }) {
// pageTemplateTeam {
//   heading
//   body
//   teamMember {
//     ... on WpTeamMember {
//       title
//       postTemplateTeam {
//         photo {
//           altText
//           sourceUrl
//         }
//         title
//         profileUrl
//       }
//     }
//   }
// }
//   }
// }
// `);
